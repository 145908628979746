import {LocaleDataModel} from '@core/models/local/locale.model';

const consult: LocaleDataModel = {
  form: {
    title: 'Толық ақпарат алу үшін',
    phone: {
      label: 'Телефон нөмірі',
      placeholder: 'Нөміріңізді енгізіңіз',
    },
    idn: {
      label: 'ЖСН',
      placeholder: 'Талапкердің ЖСН енгізіңіз',
    },
    get_consultation: 'Жеке кеңес алу',
    request_success_title: 'Өтініш жіберілді',
    request_success_message: 'Біздің эксперттер тез арада сізге хабарласады.',
    request_warning_title: 'Назар аударыңыз',
    request_warning_no_results_for_idn: `Өкінішке орай біз сіздің ҰБТ нәтижеңізді ала алмадық. Оның бірнеше себебі болуы мүмкін:<br><br>1. Сіз аппеляцияға бердіңіз. Ондай жағдайда аппеляция нәтижесі шыққанша күте тұру керек. Шыққаннан кейін қайта өтініш беріңіз.<br><br>2. Сіз ҰБТ-ны әлі тапсырмадыңыз. ҰБТ-ны тапсырып болған соң қайта өтініш беріңіз.<br><br>3. Сіздің ҰБТ нәтижеңіз жойылған. Бұл жағдайда біз сізге көмектесе алмаймыз.<br><br>4. ЖСН дұрыс жазылғанан тексеріңіз.`,
  },
  result: {
    title: 'Жеке консультация',
    subtitle_1: 'Өкінішке орай сіздің өтінішіңіз қабылданбады. Себебі сіз шекті балл жинамадыңыз. Толығырақ білу үшін видеоны көріңіз.',
    subtitle_2: 'Өкінішке орай сіздің өтінішіңіз қабылданбады. Себебі біз сізге көмектесе алмаймыз. Толығырақ видеодан көріңіз.',
    subtitle_3: 'Сіздің өтінішіңіз қабылданды. Толық ақпарат алу үшін видеоны көріңіз.',
    understood: 'Түсінікті',
  },
};

export default consult;
