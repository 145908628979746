import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  private queue = new BehaviorSubject<string[]>([]);

  queue$(): Observable<string[]> {
    return this.queue.asObservable();
  }

  pushLoaderQueue(event: string): void {
    this.queue.next([...this.queue.getValue(), event]);
  }

  popLoaderQueue(event: string): void {
    const queue = this.queue.getValue();
    const foundIndex = queue.findIndex(i => i === event);
    if (foundIndex !== -1) {
      queue.splice(foundIndex, 1);
    }
    this.queue.next([...queue]);
  }
}
