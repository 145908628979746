import {Component} from '@angular/core';
import {LoaderService} from '@core/services/local/loader.service';

@Component({
  selector: 'core-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent {

  constructor(
    public loaderService: LoaderService
  ) {
  }
}
