import {Injectable} from '@angular/core';
import {HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';

import {DialogService} from '@core/services/local/dialog.service';
import {LangService} from '@core/services/local/lang.service';
import {extractHttpErrorMessage} from '@core/utils/utils';
import {HTTP_SKIP_ERROR_HANDLING} from '@core/constants/constants';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {

  constructor(
    private dialogService: DialogService,
    private langService: LangService,
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    return next
      .handle(
        request.clone({
          setHeaders: {'Accept-Language': this.langService.langSnapshot()}
        })
      )
      .pipe(
        catchError(error => this.handleResponseError(error, request))
      );
  }

  handleResponseError(error: any, request: HttpRequest<any>): Observable<any> {
    if (request.headers.has(HTTP_SKIP_ERROR_HANDLING)) {
      return throwError(error);
    }

    let message;
    switch (true) {
      case error.status >= 500:
        message = 'Ошибка сервера';
        break;
      case error.status === 404:
        message = 'Не найдено';
        break;
      default:
        message = extractHttpErrorMessage(error.error);
    }

    this.dialogService.error({message});
    return throwError(error);
  }
}
