import {LocaleDataModel} from '@core/models/local/locale.model';

const consult: LocaleDataModel = {
  form: {
    title: 'Получить подробную информацию',
    phone: {
      label: 'Номер телефона',
      placeholder: 'Введите номер телефона',
    },
    idn: {
      label: 'ИИН',
      placeholder: 'Введите ИИН абитуриента',
    },
    get_consultation: 'Получить консультацию',
    request_success_title: 'Заявка отправлена',
    request_success_message: 'Наши специалисты очень скоро свяжутся с вами.',
    request_warning_title: 'Внимание',
    request_warning_no_results_for_idn: `К сожалению мы не смогли получить ваши результаты ЕНТ. Причины могут быть следующие:<br><br>1. Вы подали на аппеляцию. В таком случае надо подождать результаты аппеляции. Подайте заявку после результатов аппеляции.<br><br>2. Вы еще не сдали ЕНТ. Подайте заявку после сдачи ЕНТ.<br><br>3. Ваши результаты ЕНТ были аннулированы. В таком случае мы не можем вам помочь.<br><br>4. Проверьте правильность ИНН.`,
  },
  result: {
    title: 'Личная консультация',
    subtitle_1: 'К сожалению, мы не сможем вам помочь, ваша заявка не принята. У вас нет необходимого проходного балла. Подробнее об этом вы сможете узнать посмотрев видео.',
    subtitle_2: 'К сожалению, мы не сможем вам помочь, ваша заявка не принята. Подробнее об этом вы сможете узнать посмотрев видео.',
    subtitle_3: 'Ваша заявка принята. Для подробной информации посмотрите видео.',
    understood: 'Понятно',
  },
};

export default consult;
