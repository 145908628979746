import {LocaleDataModel} from '@core/models/local/locale.model';

const cabinet: LocaleDataModel = {
  countdown: {
    days: 'күн',
    hours: 'сағ.',
    minutes: 'мин.',
    seconds: 'сек.',
  },
  consult_state_not_paid: 'Төлем жасалуын күтудеміз',
  consult_state_paid: 'Төлем жасалды',
  consult_state_result: 'Нәтиже дайын',
  pay: 'Төлеу',
  discount_end_in: 'Жеңілдік уақыты',
  discount_ended: 'Жеңілдік уақыты бітті',
  rural_quota: 'Ауыл квотасы',
  serpin: 'Серпін',
  results_hint: 'Грант конкурсына өтініш қалдыру үшін қабылдау комиссиясы беретін бланкте мамандық коды мен ЖОО кодын толтырасыз (оны жоғарыда біз ұсынған нәтижеде көре аласыз) немесе ол бланкті "PDF КҮЙІНДЕ САҚТАУ" батырмасын басу арқылы сақтай аласыз. Сәттілік!',
  enroll_info: 'Жоғарғы оқу орнына тапсыруға қажетті құжаттар:<br>- Бекітілген арнайы бланк бойынша өтініш беру<br>- Жалпы орта білім аттестаты немесе колледж дипломы (оригинал)<br>- 3x4 фото (6 дана)<br>- Медициналық анықтама, форма 086-У<br>- Медициналық карта, форма 063<br>- Флюрография<br>- ҰБТ серитификаты<br>- Төлқұжатыңыздың көшірмесі<br>Құжат қабылдау уақыты 13-20 шілде арасы.<br><b>Ескерту: Құжаттар тізімін қабылдау комиссиясынан нақтылаңыз</b>',
};

export default cabinet;
