import {LocaleDataModel} from '@core/models/local/locale.model';

const about: LocaleDataModel = {
  title: 'Joo.kz туралы',
  text: 'Біз талапкерлерге грантқа түсуге көмектесеміз. Бізде 2014 жылдан бергі ҰБТ-ға қатысты барлық статистикалық мәліметтер бар. Әрбір талапкер лайықты деңгейде білім алуға құқылы.',
  author: 'Уксикбаев Канат Бекниязович',
  app_label: 'Қосымшаны жүктеу',
  contact_label: 'Байланыс',
};

export default about;
