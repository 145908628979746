import {ModuleWithProviders, NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {ModalModule} from 'ngx-bootstrap/modal';

import {LoaderComponent} from '@core/components/loader/loader.component';
import {DialogComponent} from '@core/components/dialog/dialog.component';
import {LoaderInterceptor} from '@core/interceptors/loader.intercepter';
import {RequestInterceptor} from '@core/interceptors/request.interceptor';

@NgModule({
  declarations: [
    LoaderComponent,
    DialogComponent,
  ],
  exports: [
    LoaderComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TranslateModule.forRoot(),
    ModalModule.forRoot(),
  ],
})
export class CoreModule {
  static forRoot(): ModuleWithProviders<any> {
    return {
      ngModule: CoreModule,
      providers: [
        {
          provide: HTTP_INTERCEPTORS,
          useClass: RequestInterceptor,
          multi: true
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: LoaderInterceptor,
          multi: true
        },
      ]
    };
  }
}
