export const extractHttpErrorMessage = (data: any, ignoreKeys: string[] = []): any => {
  if (!data || typeof data === 'string') {
    return data;
  }

  if (Array.isArray(data)) {
    return extractHttpErrorMessage(data[0], ignoreKeys);
  }

  const objKeys = Object.keys(data).filter(key => !ignoreKeys.includes(key));
  return extractHttpErrorMessage(data[objKeys[0]], ignoreKeys);
};

export const isNotNil = x => x !== null && x !== undefined;
