import {LocaleDataModel} from '@core/models/local/locale.model';

const home: LocaleDataModel = {
  section1: {
    title: '<span class="antiquewhite">Поможем поступить на грант</span><br>с вашими баллами ЕНТ',
    subtitle: 'Если после нашей консультации вы не поступите, мы вернем вам деньги',
    get_consultation: 'Получить консультацию',
    discount: 'Оставь заявку прямо сейчас и получи <span class="text-orange-light">скидку 20%</span>',
  },
  section2: {
    helped: 'В 2020 году с нашей помощью',
    students_all: 'из <span class="text-orange">837</span> учеников',
    students_got_grant: '<span class="text-orange">828</span> поступили на грант',
    reviews: '⭐️<br>Отзывы учеников поступивших с нами на грант в 2020 году',
  },
  section3: {
    title: 'Лучшее приложение для абитуриентов',
  },
  section4: {
    title: 'СМИ о нас',
    feature1: 'Канат УКСИКБАЕВ, автор проекта Joo.kz: Поможем абитуриентам учиться бесплатно',
    feature2: 'Работает портал, помогающий выиграть государственный грант',
    feature3: 'Можно узнать вероятность поступления на грант. Автор проекта',
    feature4: 'Поступить из карантина. Как сервис Joo.kz помогает абитуриентам и системе образования',
  },
  section5: {
    title: 'Партнеры',
  },
  section6: {
    title: 'Личная консультация',
    phone: {
      label: 'Номер телефона',
      placeholder: 'Введите номер телефона',
    },
    idn: {
      label: 'ИИН',
      placeholder: 'Введите ИИН абитуриента',
    },
    get_consultation: 'Получить консультацию',
  },
};

export default home;
