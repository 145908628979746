import {LocaleModel} from '@core/models/local/locale.model';
import home from '@assets/i18n/home/ru';
import consult from '@assets/i18n/consult/ru';
import cabinet from '@assets/i18n/cabinet/ru';
import about from '@assets/i18n/about/ru';
import footer from '@assets/i18n/footer/ru';
import general from '@assets/i18n/general/ru';

const localeRu: LocaleModel = {
  lang: 'ru',
  data: {
    home,
    consult,
    cabinet,
    about,
    footer,
    general,
  }
};

export default localeRu;
