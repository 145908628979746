import {LocaleModel} from '@core/models/local/locale.model';
import home from '@assets/i18n/home/kk';
import consult from '@assets/i18n/consult/kk';
import cabinet from '@assets/i18n/cabinet/kk';
import about from '@assets/i18n/about/kk';
import footer from '@assets/i18n/footer/kk';
import general from '@assets/i18n/general/kk';

const localeKk: LocaleModel = {
  lang: 'kk',
  data: {
    home,
    consult,
    cabinet,
    about,
    footer,
    general,
  }
};

export default localeKk;
