import {LocaleDataModel} from '@core/models/local/locale.model';

const about: LocaleDataModel = {
  title: 'О Joo.kz',
  text: 'Мы помогаем абитуриентам поступить на грант. У нас есть большая база всех специальностей, кто куда с каким баллом поступал в предыдущих годах. Мы хотим, чтобы каждый абитуриент получил то что справедливо заслуживает.',
  author: 'Уксикбаев Канат Бекниязович',
  app_label: 'Скачать приложение',
  contact_label: 'Наши контакты',
};

export default about;
