import {LocaleDataModel} from '@core/models/local/locale.model';

const home: LocaleDataModel = {
  section1: {
    title: 'ҰБТ-да жинаған баллыңмен <span class="antiquewhite">грантқа түсуге көмектесеміз</span>',
    subtitle: 'Егер грантқа түсіре алмасақ, төлеміңізді қайтарып береміз',
    get_consultation: 'Жеке кеңес алу',
    discount: 'Өтінішті қазір қалдырып, <span class="text-orange-light">20% жеңілдікке</span> ие бол',
  },
  section2: {
    helped: '2020 жылы біз көмектескен',
    students_all: '<span class="text-orange">837</span> оқушыдан',
    students_got_grant: '<span class="text-orange">828-і</span> грантқа түсті',
    reviews: '⭐️<br>2020 жылы біздің көмегімізбен грантқа түскен оқушылардың пікірі',
  },
  section3: {
    title: 'Талапкерлер үшін ең керемет бағдарлама',
  },
  section4: {
    title: 'БАҚ біз туралы',
    feature1: 'Қанат ӨКСІКБАЕВ, Jоо.kz жобасының авторы: Талапкерлердің тегін білім алуына көмектесеміз',
    feature2: 'Мемлекеттік білім грантын ұтып алуға көмектесетін портал жұмыс істейді',
    feature3: 'Грантқа түсу мүмкіндігін мобильді қосымша арқылы білуге болады – жоба жетекшісі',
    feature4: 'Поступить из карантина. Как сервис Joo.kz помогает абитуриентам и системе образования',
  },
  section5: {
    title: 'Серіктестер',
  },
  section6: {
    title: 'Жеке консультация',
    phone: {
      label: 'Телефон нөмірі',
      placeholder: 'Нөміріңізді енгізіңіз',
    },
    idn: {
      label: 'ЖСН',
      placeholder: 'Талапкердің ЖСН енгізіңіз',
    },
    get_consultation: 'Жеке кеңес алу',
  },
};

export default home;
