<div class="modal-header" *ngIf="options.title">{{options.title | translate}}</div>

<div class="modal-body" [innerHTML]="options.message | translate"></div>

<div class="modal-footer justify-content-end">

  <button
    class="btn btn-outline-secondary mr-2"
    *ngIf="options.cancelText"
    (click)="onClose()"
  >
    {{options.cancelText | translate}}
  </button>

  <button (click)="onConfirm()">{{options.confirmText | translate}}</button>

</div>
