import {ConsultStateType} from '@core/types/api/consult-state.type';

export const CONSULT_STATE_NOT_PAID = 0;
export const CONSULT_STATE_PAID = 1;
export const CONSULT_STATE_RESULT = 2;

export const CONSULT_STATE_TYPE_MAP: { [state: number]: ConsultStateType } = {
  [CONSULT_STATE_NOT_PAID]: 'not_paid',
  [CONSULT_STATE_PAID]: 'paid',
  [CONSULT_STATE_RESULT]: 'result',
};

export const HTTP_SKIP_ERROR_HANDLING = 'skip_error_handling';
