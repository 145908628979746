import {LocaleDataModel} from '@core/models/local/locale.model';

const cabinet: LocaleDataModel = {
  countdown: {
    days: 'д.',
    hours: 'час.',
    minutes: 'мин.',
    seconds: 'сек.',
  },
  consult_state_not_paid: 'Ожидает оплату',
  consult_state_paid: 'Успешно оплачено',
  consult_state_result: 'Результаты готовы',
  pay: 'Оплатить',
  discount_end_in: 'Скидка длится',
  discount_ended: 'Время скидки вышло',
  rural_quota: 'Сельская квота',
  serpin: 'Серпин',
  results_hint: 'Никакой Университет не имеет права не принимать ваше заявление в другие ВУЗы. Если будут говорить, что не принимают документы, если в вашем списке нет этого Университета, отправьте нам контактные данные этого человека и название ВУЗа',
  enroll_info: 'Документы необходимые для поступления:<br>- Заявление на бланке установленного образца;<br>- Аттестат о среднем общем или диплом о начальном профессиональном или среднем профессиональном образовании (оригинал);<br>- Фото формата 3x4 - 6 шт.;<br>- Медицинская справка по форме 086-У;<br>- Прививочная карта по форме 063;<br>- Снимок флюрографии;<br>- Сертификат ЕНТ;<br>- Копия удостоверении личности;<br>- Приписное свидетельство (для юношей).<br>Прием документов с 13 по 20 июля<br><b>Предупреждение: Уточните перечень документов в приемной комиссии</b>',
};

export default cabinet;
